import React from "react"
import Layout from "../components/Layout"
import { Link } from 'gatsby'

export default () => {
  return (
    <Layout>
      <div className="flex flex-col items-center py-20">
        <h1 className="text-blue-700 text-6xl">404</h1>
        <div className="text-blue-900 text-center">
          <p>Oops. We're sorry! The page you requested was not found.</p>
          <p>Please go back to the <Link className="text-blue-800" to="/">homepage</Link> or contact us <Link className="text-blue-800" to="/contact">here.</Link></p>
        </div>
      </div>
    </Layout>
  )
}
